var generic = generic || {};

$(function() {
  var path = window.location.pathname;
  var pathArray = path.split('/');

  var cookieLocale = generic.cookie('LOCALE');
  if (cookieLocale !== undefined && cookieLocale.toLowerCase() === 'fr_be' && pathArray[1].toLowerCase() !== 'fr-e-be' && path.indexOf('.tmpl') === -1) {
    var full_url = window.location.href;
    var appends = '';
    //BugId:254463
    if (full_url.indexOf('#') > 0) {
      const full_url_parts = full_url.split('#'); //BugId:254463
      appends = '#' + full_url_parts[1];
    } else if (full_url.indexOf('?') > 0) {
      const full_url_parts = full_url.split('?');
      appends = '?' + full_url_parts[1];
    }

    window.location.href = window.location.protocol + '//' + window.location.host + '/fr-e-BE' + window.location.pathname + appends;
    $('a[data-mp-lang="de"]').removeClass('language-select__link--selected');
    $('a[data-mp-lang="fr"]').addClass('language-select__link--selected');
  }

  $('.js-switch-lang-link').on('click', function(event) {
    event.preventDefault();
    var lang = $(this).attr('data-mp-lang') === 'de' ? 'nl_BE' : 'fr_BE';
    return switchLanguageBE(lang);
  });

  $('.js-lang-link1').on('click', function(event) {
    event.preventDefault();
    var lang = 'nl_BE';
    return switchLanguageBE(lang);
  });

  $('.js-lang-link2').on('click', function(event) {
    event.preventDefault();
    var lang = 'fr_BE';
    return switchLanguageBE(lang);
  });

  function switchLanguageBE(lang) {
    var domains = { 'nl_BE': '/', 'fr_BE': '/fr-e-BE/' };
    var pathArrayCheck = pathArray[1] === '' ? '/' : pathArray[1].replace(/^/, '/').replace(/$/, '/');

    if (lang !== generic.cookie('LOCALE') || pathArrayCheck !== domains[lang]) {
      var path = window.location.pathname.replace(/\/fr-e-BE/, '');
      document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      generic.cookie('LOCALE', lang, {path: '/'});

      if (path.indexOf('.tmpl') > 0) {
        domains[lang] = '';
      }

      try {
        if (Drupal.settings.elc_common[lang + '_url'] !== undefined && path !== '/') {
          path = Drupal.settings.elc_common[lang + '_url'];
        }
      } catch (error) {
        //Left empty intentionally
      }

      if (path === '/') {
        path = '';
      }

      var site_path = window.location.host + domains[lang] + path;
      site_path = site_path.replace(/\/+/g, '/');//BugId:229567 - To remove the extra slashes in the path
      var url = window.location.protocol + '//' + site_path;
      var full_url = window.location.href;
      var appends = '';

      if (full_url.indexOf('#') > 0) {
        const full_url_parts = full_url.split('#'); //BugId:254463
        appends = '#' + full_url_parts[1];
      } else if (full_url.indexOf('?') > 0) {
        const full_url_parts = full_url.split('?');
        appends = '?' + full_url_parts[1];
      }

      window.location.href = url + appends;
    }
  }
});
